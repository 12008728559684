import React from 'react';

export const MainLayoutNavContext = React.createContext({
  primaryMenuToExpand: {},
  primaryMenuExpanded: {},
  secondaryMenuToExpand: {},
  secondaryMenuExpanded: {},
  navMenuAnimating: false,
  shouldLanguageMenuOpen: false,
  toggleLanguageMenu: () => {},
  setPrimaryMenuToExpand: () => {},
  setPrimaryMenuExpanded: () => {},
  setSecondaryMenuToExpand: () => {},
  setSecondaryMenuExpanded: () => {},
  setNavMenuAnimating: () => {},
});
