import React from 'react';
import * as style from './LanguageItem.module.scss';
import { getFlagFromCode } from '../../utilities';

const LanguageItem = ({
  name,
  countryCode,
  langSlug,
  locale,
  className,
  setNewActiveLanguage,
  ...rest
}) => {
  return (
    <div
      className={`clearLinkStyle ${style.languageItem} ${className}`}
      onClick={() => {
        if (typeof window === 'undefined') return;
        setNewActiveLanguage(locale);
        // localStorage.setItem('ridestoreLocale', locale);
        // window.dispatchEvent(new Event('storage'));
      }}
      {...rest}
    >
      {React.cloneElement(getFlagFromCode(countryCode), {
        width: '20px',
        className: style.languageFlag,
      })}
      <div className={style.languageName}>{name}</div>
    </div>
  );
};

export default LanguageItem;
