import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Media } from 'gatsby-plugin-fresnel';
import { useTranslation } from 'react-i18next';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as style from './ListItemPost.module.scss';
import { datePostedGmtToString } from '../../utilities';

const ListItemPost = (props) => {
  const {
    title,
    category,
    categoryLink,
    dateGmt,
    readTime,
    featuredImgData,
    featuredImgDataMobile,
    imgAltText,
    imgHoverTitle,
    slug,
  } = props;

  const { t } = useTranslation();

  return (
    <div className={style.listItemPost}>
      <Link
        className={`clearLinkStyle d-block ${style.featuredImageContainer}`}
        to={slug}
      >
        {featuredImgData && featuredImgData?.images && (
          <Media greaterThanOrEqual="tablet">
            {(mediaClassNames) => (
              <GatsbyImage
                image={featuredImgData}
                className={mediaClassNames}
                alt={imgAltText}
                title={imgHoverTitle}
                loading="eager"
              />
            )}
          </Media>
        )}
        {featuredImgDataMobile && featuredImgDataMobile?.images && (
          <Media lessThan="tablet">
            {(mediaClassNames) => (
              <GatsbyImage
                image={featuredImgDataMobile}
                className={mediaClassNames}
                imgClassName=""
                alt={imgAltText}
                title={imgHoverTitle}
                loading="eager"
              />
            )}
          </Media>
        )}
      </Link>
      <div className={style.postText}>
        <Link
          className={`clearLinkStyle d-block ${style.category}`}
          to={categoryLink}
        >
          {category}
        </Link>
        <Link className={`clearLinkStyle d-block ${style.title}`} to={slug}>
          {title}
        </Link>
        <Media lessThan="tablet">
          {(mediaClassNames) => (
            <div className={`${mediaClassNames} flex-spacer`} />
          )}
        </Media>
        <div className={style.date}>
          {datePostedGmtToString(dateGmt)} ·{' '}
          {t('articles.timeToRead', { timeToRead: readTime ?? 5 })}
        </div>
      </div>
    </div>
  );
};

ListItemPost.defaultProps = {
  featuredImgDataMobile: null,
  imgAltText: 'image',
  imgHoverTitle: '',
  readTime: 5,
};

ListItemPost.propTypes = {
  /** Post title */
  title: PropTypes.string.isRequired,
  /** Post category */
  category: PropTypes.string.isRequired,
  /** Date posted GMT (later converted to local) */
  dateGmt: PropTypes.string.isRequired,
  /** Time to read the post (minutes) */
  readTime: PropTypes.number,
  /** Slug of a post */
  slug: PropTypes.string.isRequired,
  /** Featured image (gatsby-image) */
  featuredImgData: PropTypes.object.isRequired,
  /** Featured image (gatsby-image). Mobile only */
  featuredImgDataMobile: PropTypes.object,
  /** Image alt text for when image is not displayed (and SEO) */
  imgAltText: PropTypes.string,
  /** Image title to display a tooltip on hover. */
  imgHoverTitle: PropTypes.string,
};

export default ListItemPost;
