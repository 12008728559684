// extracted by mini-css-extract-plugin
export var header = "HeaderNavTablet-module--header--36nKG";
export var headerWrapper = "HeaderNavTablet-module--headerWrapper--2-5J3";
export var headerMain = "HeaderNavTablet-module--headerMain--2Ba9X";
export var iconHeader = "HeaderNavTablet-module--iconHeader--Axzb7";
export var horizontalSpacer = "HeaderNavTablet-module--horizontalSpacer--2qh6a";
export var burgerContainer = "HeaderNavTablet-module--burgerContainer--2tmv2";
export var languageOrSearchScroller = "HeaderNavTablet-module--languageOrSearchScroller--GhTFq";
export var languageOrSearch = "HeaderNavTablet-module--languageOrSearch--m1QkE";
export var languageSwitch = "HeaderNavTablet-module--languageSwitch--1vOay";
export var languageSwitchFlag = "HeaderNavTablet-module--languageSwitchFlag--2h7Z8";
export var languageChevronDown = "HeaderNavTablet-module--languageChevronDown--2fuZD";
export var headerSecondary = "HeaderNavTablet-module--headerSecondary--3JwTH";
export var secondaryTitle = "HeaderNavTablet-module--secondaryTitle--2ga7m";
export var iconSecondary = "HeaderNavTablet-module--iconSecondary--2Ga0P";