// extracted by mini-css-extract-plugin
export var topMargin = "MainLayout-module--topMargin--oHXiw";
export var pageContent = "MainLayout-module--pageContent--3AfKu";
export var bottomMarginInner = "MainLayout-module--bottomMarginInner--30aa3";
export var bottomNav = "MainLayout-module--bottomNav--1obuZ";
export var bottomNavItemsContainer = "MainLayout-module--bottomNavItemsContainer--3jdqs";
export var navItem = "MainLayout-module--navItem--1FSab";
export var bottomLinksSection = "MainLayout-module--bottomLinksSection--2-Pyp";
export var bottomLinksRow = "MainLayout-module--bottomLinksRow--M0zpH";
export var bottomLinksTitle = "MainLayout-module--bottomLinksTitle--2De5j";
export var bottomLinksColumn = "MainLayout-module--bottomLinksColumn--LBRjm";
export var iconRow = "MainLayout-module--iconRow--1ISoY";
export var icon = "MainLayout-module--icon--3IBiT";
export var bottomSocialNetworkMenu = "MainLayout-module--bottomSocialNetworkMenu--27N8L";
export var footer = "MainLayout-module--footer--6PaDf";