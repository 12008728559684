import React, { useContext, useEffect, useRef, useState } from 'react';
import anime from 'animejs/lib/anime.es';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import * as style from './Header.module.scss';
import { ReactComponent as BurgerOpenIcon } from '../../assets/vectors/Burger.svg';
import { ReactComponent as BurgerCloseIcon } from '../../assets/vectors/Burger-close.svg';
import { ReactComponent as ChevronLeftIcon } from '../../assets/vectors/Chevron-left.svg';
import { ReactComponent as ChevronDownIcon } from '../../assets/vectors/Chevron-down.svg';
import { ReactComponent as SearchIcon } from '../../assets/vectors/Search.svg';
import { getFlagFromCode, isObjectEmpty } from '../../utilities';
import { MainLayoutNavContext } from '../../contexts/MainLayoutNavContext';
import { getLanguageFromField } from '../../utilities/sharedUtils';
import { MainLayoutLocaleContext } from '../../contexts/MainLayoutLocaleContext';
import RidestoreLogoLink from '../RidestoreLogoLink';

const Header = (props) => {
  const {
    shouldNavMenuMobileOpen,
    shouldNavMenuTabletOpen,
    toggleNavMenuMobile,
    toggleNavMenuTablet,
    openSearchMenu,
    headerRef,
    sustainability,
  } = props;

  const {
    primaryMenuToExpand,
    primaryMenuExpanded,
    secondaryMenuToExpand,
    secondaryMenuExpanded,
    navMenuAnimating,
    shouldLanguageMenuOpen,
    toggleLanguageMenu,
    setPrimaryMenuToExpand,
    setSecondaryMenuToExpand,
  } = useContext(MainLayoutNavContext);

  const { t } = useTranslation();
  const { locale } = useContext(MainLayoutLocaleContext);

  const headerWrapperRef = useRef();
  const [displayedHeader, setDisplayedHeader] = useState('main');

  useEffect(() => {
    if (shouldNavMenuTabletOpen) return;
    if (
      primaryMenuToExpand?.childMenuItems?.length > 0 &&
      displayedHeader === 'main'
    ) {
      /**
       * Animate to the secondary/tertiary header if any primary menu item is expanded and header is now 'main'
       */
      anime({
        targets: headerWrapperRef.current,
        left: {
          value: '-100%',
          duration: '500',
          easing: 'easeInOutQuint',
          complete: () => {
            setDisplayedHeader('secondary');
          },
        },
      });
    } else if (
      isObjectEmpty(primaryMenuToExpand) &&
      displayedHeader === 'secondary'
    ) {
      /**
       * Animate to the main header if primary menu is collapsed and header is now 'secondary'
       */
      anime({
        targets: headerWrapperRef.current,
        left: {
          value: '0%',
          duration: '500',
          easing: 'easeInOutQuint',
          complete: () => {
            setDisplayedHeader('main');
          },
        },
      });
    }
  }, [primaryMenuToExpand, shouldNavMenuTabletOpen]);

  const collapseAllMenus = () => {
    if (navMenuAnimating) return;
    setPrimaryMenuToExpand({});
    setSecondaryMenuToExpand({});
  };

  const collapseCurrentMenu = () => {
    if (navMenuAnimating) return;
    if (!isObjectEmpty(secondaryMenuToExpand)) {
      setSecondaryMenuToExpand({});
    } else if (!isObjectEmpty(primaryMenuToExpand)) {
      setPrimaryMenuToExpand({});
    }
  };

  const languageOrSearchRef = useRef();

  useEffect(() => {
    if (shouldNavMenuMobileOpen) {
      /**
       * Show Language picker (flag and chevron)
       */
      anime({
        targets: languageOrSearchRef.current,
        marginTop: {
          value: '0px',
          duration: '400',
          easing: 'easeInOutCubic',
        },
      });
    } else {
      /**
       * Show Search icon
       */
      anime({
        targets: languageOrSearchRef.current,
        marginTop: {
          value: '-140px',
          duration: '400',
          easing: 'easeInOutCubic',
        },
      });
    }
  }, [shouldNavMenuMobileOpen]);

  useEffect(() => {}, [shouldNavMenuTabletOpen]);

  useEffect(() => {
    if (shouldLanguageMenuOpen) {
      /**
       * Rotate chevron up
       */
      anime({
        targets: `.${style.languageChevronDown}`,
        rotate: {
          value: ['0', '180'],
          duration: '400',
          easing: 'easeInOutCubic',
        },
      });
    } else {
      /**
       * Rotate chevron down
       */
      anime({
        targets: `.${style.languageChevronDown}`,
        rotate: {
          value: '360',
          duration: '400',
          easing: 'easeInOutCubic',
        },
      });
    }
  }, [shouldLanguageMenuOpen]);

  const [flagComponent, setFlagComponent] = useState();

  useEffect(() => {
    setFlagComponent(
      React.cloneElement(
        getFlagFromCode(getLanguageFromField('locale', locale).countryCode),
        { width: '20', className: style.languageSwitchFlag }
      )
    );
  }, [locale]);

  const isMagazine = true;

  return (
    <header
      className={`${style.header} ${
        sustainability ? style.sustainability : ''
      }`}
      ref={headerRef}
    >
      <div
        className={classNames(style.headerWrapper, {
          [style.logoOnly]: !isMagazine,
        })}
        ref={headerWrapperRef}
      >
        <div
          className={classNames(style.headerMain, {
            [style.logoOnly]: !isMagazine,
          })}
        >
          {!sustainability && isMagazine && (
            <div className={style.burgerContainer} title={t('labels.navMenu')}>
              {!shouldNavMenuMobileOpen ? (
                <BurgerOpenIcon
                  className={style.iconHeader}
                  alt="menu"
                  onClick={toggleNavMenuMobile}
                  width={20}
                  height={20}
                />
              ) : (
                <BurgerCloseIcon
                  className={style.iconHeader}
                  alt="menu"
                  onClick={toggleNavMenuMobile}
                  width={20}
                  height={20}
                />
              )}
            </div>
          )}
          {!sustainability && isMagazine && (
            <div
              className={style.burgerContainerTablet}
              title={t('labels.navMenu')}
            >
              {!shouldNavMenuTabletOpen ? (
                <BurgerOpenIcon
                  className={style.iconHeader}
                  alt="menu"
                  onClick={toggleNavMenuTablet}
                  width={20}
                  height={20}
                />
              ) : (
                <BurgerCloseIcon
                  className={style.iconHeader}
                  alt="menu"
                  onClick={toggleNavMenuTablet}
                  width={20}
                  height={20}
                />
              )}
            </div>
          )}
          <RidestoreLogoLink
            className={style.ridestoreLogo}
            sustainability={sustainability}
          />
          {isMagazine && (
            <div className={style.languageOrSearchScroller}>
              <div className={style.languageOrSearch} ref={languageOrSearchRef}>
                {!sustainability && (
                  <div>
                    <div
                      className={style.languageSwitch}
                      title={t('labels.changeLanguage')}
                      onClick={toggleLanguageMenu}
                    >
                      {flagComponent}
                      <ChevronDownIcon
                        className={style.languageChevronDown}
                        width={20}
                        height={20}
                      />
                    </div>
                  </div>
                )}
                <div />
                <div title={t('labels.search')}>
                  <SearchIcon
                    className={style.iconHeader}
                    alt="search"
                    onClick={() => {
                      openSearchMenu();
                    }}
                    width={20}
                    height={20}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {isMagazine && (
          <div className={style.headerSecondary}>
            <ChevronLeftIcon
              className={style.iconSecondary}
              alt="menu"
              onClick={() => {
                collapseCurrentMenu();
              }}
              width={20}
              height={20}
            />
            <div className={style.secondaryTitle}>
              {secondaryMenuExpanded?.label || primaryMenuExpanded?.label}
            </div>
            <div className={style.horizontalSpacer} />
            <BurgerCloseIcon
              className={style.iconSecondary}
              alt="menu"
              width={20}
              height={20}
              onClick={() => {
                collapseAllMenus();
                toggleNavMenuMobile();
              }}
            />
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
