// extracted by mini-css-extract-plugin
export var navSlider = "NavMenu-module--navSlider---CQfH";
export var navSliderTablet = "NavMenu-module--navSliderTablet--3DUdg";
export var navContainer = "NavMenu-module--navContainer--1mjTH";
export var navContainerTablet = "NavMenu-module--navContainerTablet--3l1jz";
export var navMenuPrimary = "NavMenu-module--navMenuPrimary--1fsWX";
export var whiteSection = "NavMenu-module--whiteSection--1w2v_";
export var blackSection = "NavMenu-module--blackSection--1qA7H";
export var blackSectionTablet = "NavMenu-module--blackSectionTablet--11lDC";
export var blackSectionLinks = "NavMenu-module--blackSectionLinks--qlaR_";
export var footer = "NavMenu-module--footer--1YeSA";
export var icon = "NavMenu-module--icon--3ZcCz";
export var logoWhite = "NavMenu-module--logoWhite--3Jdh7";
export var verticalSpacer = "NavMenu-module--verticalSpacer--11BjA";
export var horizontalSpacer = "NavMenu-module--horizontalSpacer--1Adri";
export var navMenuSecondary = "NavMenu-module--navMenuSecondary--X781j";
export var secondarySection = "NavMenu-module--secondarySection--1zcvb";
export var secondaryImage = "NavMenu-module--secondaryImage--EpDkG";
export var secondarySectionText = "NavMenu-module--secondarySectionText--1Va0H";
export var navMenuTertiary = "NavMenu-module--navMenuTertiary--wEIly";
export var tertiaryHeadingImage = "NavMenu-module--tertiaryHeadingImage--37woD";
export var tertiarySection = "NavMenu-module--tertiarySection--kgP98";
export var tertiarySectionItem = "NavMenu-module--tertiarySectionItem--2huaH";
export var tertiaryItemImage = "NavMenu-module--tertiaryItemImage--eOUeC";
export var tertiaryItemLabel = "NavMenu-module--tertiaryItemLabel--1AcTn";