import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.json';
import de from './de_DE.json';
import da from './da_DK.json';
import fr from './fr_FR.json';
import fi from './fi.json';
import nl from './nl_NL.json';
import sv from './sv_SE.json';
import it from './it_IT.json';
import es from './es_ES.json';
import { getLanguageFromField } from '../utilities/sharedUtils';

const languageDetector = new LanguageDetector();

const ridestoreLocaleDetector = {
  name: 'ridestoreLocaleDetector',

  lookup() {
    // if (typeof window === 'undefined') return 'en-GB';
    // const location = window.location.origin;

    // noinspection UnnecessaryLocalVariableJS
    const i18nLocale = getLanguageFromField(
      'languageCode',
      process.env.GATSBY_ACTIVE_LANGUAGE || 'EN'
    )?.i18nLocale;

    return i18nLocale;
  },

  // eslint-disable-next-line no-unused-vars
  cacheUserLanguage(lng) {
    // if (typeof window === 'undefined') return;
    // localStorage.setItem('ridestoreLocale', lng.replace('-', '_-_'));
  },
};

languageDetector.addDetector(ridestoreLocaleDetector);

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
      sv: {
        translation: sv,
      },
      fr: {
        translation: fr,
      },
      it: {
        translation: it,
      },
      es: {
        translation: es,
      },
      nl: {
        translation: nl,
      },
      fi: {
        translation: fi,
      },
      da: {
        translation: da,
      },
    },
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['ridestoreLocaleDetector'],
    },
  });

export default i18n;
