import React, { useContext, useEffect, useRef } from 'react';
import anime from 'animejs/lib/anime.es';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as style from './NavMenu.module.scss';
import { ReactComponent as ChevronRightIcon } from '../../assets/vectors/Chevron-right.svg';
import RidestoreLogoLink from '../RidestoreLogoLink';
import { isObjectEmpty } from '../../utilities';
import getIconComponentFromIconName from '../../utilities/iconGetter';
import { MainLayoutNavContext } from '../../contexts/MainLayoutNavContext';
import { pathJoinSafeSlashes } from '../../utilities/pathJoinSafe';
import { CATEGORIES_URL } from '../../utilities/urlConstants';

const NavMenuMobile = (props) => {
  const { topMenuHierarchy, bottomMenu, socialMenu, variant } = props;

  const {
    primaryMenuToExpand,
    primaryMenuExpanded,
    secondaryMenuToExpand,
    secondaryMenuExpanded,
    navMenuAnimating,
    setPrimaryMenuToExpand,
    setSecondaryMenuToExpand,
    setPrimaryMenuExpanded,
    setSecondaryMenuExpanded,
    setNavMenuAnimating,
  } = useContext(MainLayoutNavContext);

  const { t } = useTranslation();

  const navWrapperRef = useRef();
  const blackSectionRef = useRef();

  useEffect(() => {
    if (
      primaryMenuToExpand?.childMenuItems?.length > 0 &&
      isObjectEmpty(primaryMenuExpanded)
    ) {
      /**
       * Expand primary menu if primary menu is not yet expanded but set to expand
       */
      setNavMenuAnimating(true);
      setPrimaryMenuExpanded(primaryMenuToExpand);
      anime({
        targets: navWrapperRef.current,
        left: {
          value: '-100%',
          easing: 'easeInOutQuint',
          duration: 500,
        },
        complete: () => {
          setNavMenuAnimating(false);
        },
      });
    } else if (
      isObjectEmpty(primaryMenuToExpand) &&
      !isObjectEmpty(primaryMenuExpanded)
    ) {
      /**
       * Collapse primary menu if primary menu is shown and set to collapse
       */
      setNavMenuAnimating(true);
      anime({
        targets: navWrapperRef.current,
        left: {
          value: '0%',
          easing: 'easeInOutQuint',
          duration: 500,
        },
        complete: () => {
          setPrimaryMenuExpanded({});
          setSecondaryMenuExpanded({});
          setNavMenuAnimating(false);
        },
      });
    } else if (
      !isObjectEmpty(primaryMenuExpanded) &&
      isObjectEmpty(secondaryMenuExpanded) &&
      !isObjectEmpty(secondaryMenuToExpand)
    ) {
      /**
       * Expand secondary menu if primary menu is expanded, secondary menu is not yet expanded but set to expand
       */
      setNavMenuAnimating(true);
      setSecondaryMenuExpanded(secondaryMenuToExpand);
      anime({
        targets: navWrapperRef.current,
        left: {
          value: '-200%',
          easing: 'easeInOutQuint',
          duration: 500,
        },
        complete: () => {
          setNavMenuAnimating(false);
        },
      });
    } else if (
      !isObjectEmpty(primaryMenuExpanded) &&
      !isObjectEmpty(secondaryMenuExpanded) &&
      isObjectEmpty(secondaryMenuToExpand)
    ) {
      /**
       * Collapse secondary menu if primary menu is expanded, secondary menu is expanded and set to collapse
       */
      setNavMenuAnimating(true);
      anime({
        targets: navWrapperRef.current,
        left: {
          value: '-100%',
          easing: 'easeInOutQuint',
          duration: 500,
        },
        complete: () => {
          setSecondaryMenuExpanded({});
          setNavMenuAnimating(false);
        },
      });
    }
  }, [primaryMenuToExpand, secondaryMenuToExpand]);

  const expandPrimaryMenu = (menuHierarchyObject, primaryId) => {
    if (navMenuAnimating) return;
    const primaryMenuClicked = menuHierarchyObject.find(
      (primaryMenu) => primaryMenu.id === primaryId
    );
    if (primaryMenuClicked.childMenuItems.length > 0) {
      setPrimaryMenuToExpand(primaryMenuClicked);
    }
  };

  const expandSecondaryMenu = (primaryMenu, secondaryId) => {
    if (navMenuAnimating) return;
    const secondaryMenuClicked = primaryMenu?.childMenuItems?.find(
      (secondaryMenu) => secondaryMenu.id === secondaryId
    );
    if (secondaryMenuClicked?.childMenuItems?.length > 0) {
      setSecondaryMenuToExpand(secondaryMenuClicked);
    }
  };

  return (
    <div
      className={`${style.navSlider} ${
        variant === 'tablet' ? style.navSliderTablet : ''
      }`}
    >
      <div
        className={`${style.navContainer} ${
          variant === 'tablet' ? style.navContainerTablet : ''
        }`}
        ref={navWrapperRef}
        id="nav-wrapper"
        style={{ left: 0 }}
      >
        {/* Primary navigation menu */}
        <nav className={style.navMenuPrimary}>
          <div className={style.whiteSection}>
            {topMenuHierarchy?.map((menuItem) =>
              menuItem?.childMenuItems?.length ? (
                <div
                  key={menuItem.id}
                  onClick={() => {
                    expandPrimaryMenu(topMenuHierarchy, menuItem.id);
                  }}
                >
                  {menuItem?.label}
                </div>
              ) : (
                <Link
                  key={menuItem.id}
                  to={pathJoinSafeSlashes(
                    CATEGORIES_URL,
                    menuItem?.connectedNode?.node?.slug
                  )}
                  className="clearLinkStyle d-block"
                >
                  {menuItem?.label}
                </Link>
              )
            )}
          </div>
          <div
            className={`${style.blackSection} ${
              variant === 'tablet' ? style.blackSectionTablet : ''
            }`}
            ref={blackSectionRef}
          >
            <div className={style.blackSectionLinks}>
              {bottomMenu?.menuItems?.nodes?.map((menuItem) => (
                <a
                  key={menuItem.id}
                  href={menuItem?.url}
                  className="clearLinkStyle d-block"
                >
                  {menuItem?.label}
                </a>
              ))}
            </div>
            <div className={style.verticalSpacer} />
            <div className={style.footer}>
              {socialMenu?.menuItems?.nodes?.map((socialIcon) => (
                <a
                  key={socialIcon.id}
                  href={socialIcon?.url}
                  className={`clearLinkStyle d-inline-flex ${style.icon}`}
                  title={t('labels.ridestoreOn', {
                    socialNetworkName: socialIcon.label,
                  })}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {getIconComponentFromIconName(`${socialIcon?.label}White`)}
                </a>
              ))}
              <div className={style.horizontalSpacer} />
              <RidestoreLogoLink
                width={103}
                height={25}
                className={style.logoWhite}
              />
            </div>
          </div>
        </nav>
        {/* Secondary navigation menu */}
        <nav className={style.navMenuSecondary}>
          {primaryMenuExpanded?.childMenuItems?.map((secondaryItem) => {
            const imgData =
              secondaryItem?.MenuItemImages?.menuItemImages?.imgixImage
                ?.gatsbyImageData;

            return (
              <div
                key={secondaryItem.id}
                className={style.secondarySection}
                onClick={() => {
                  expandSecondaryMenu(primaryMenuExpanded, secondaryItem.id);
                }}
              >
                {imgData?.images && (
                  <GatsbyImage
                    image={imgData}
                    className={style.secondaryImage}
                    alt="section cover"
                  />
                )}
                <div className={style.secondarySectionText}>
                  <div>{secondaryItem?.label}</div>
                  <ChevronRightIcon width={20} height={20} />
                </div>
              </div>
            );
          })}
        </nav>
        {/* Tertiary navigation menu */}
        <nav className={style.navMenuTertiary}>
          {!isObjectEmpty(secondaryMenuExpanded) && (
            <>
              {secondaryMenuExpanded?.MenuItemImages?.menuItemImages?.imgixImage
                ?.gatsbyImageData?.images && (
                <GatsbyImage
                  image={
                    secondaryMenuExpanded?.MenuItemImages?.menuItemImages
                      ?.imgixImage?.gatsbyImageData
                  }
                  className={style.tertiaryHeadingImage}
                  alt="section cover"
                />
              )}

              <div className={style.tertiarySection}>
                {secondaryMenuExpanded?.childMenuItems?.map((tertiaryItem) => {
                  const imgData =
                    tertiaryItem?.MenuItemImages?.menuItemImages?.imgixImage
                      ?.gatsbyImageData_sm;

                  return (
                    <Link
                      className={`clearLinkStyle d-block ${style.tertiarySectionItem}`}
                      key={tertiaryItem.id}
                      to={pathJoinSafeSlashes(
                        tertiaryItem?.connectedNode?.node?.slug
                      )}
                    >
                      {imgData?.images && (
                        <GatsbyImage
                          image={imgData}
                          className={style.tertiaryItemImage}
                          alt="section cover"
                        />
                      )}
                      <div className={style.tertiaryItemLabel}>
                        {tertiaryItem.label}
                      </div>
                      <div className={style.horizontalSpacer} />
                      <ChevronRightIcon width={20} height={20} />
                    </Link>
                  );
                })}
              </div>
            </>
          )}
        </nav>
      </div>
    </div>
  );
};

export default NavMenuMobile;
