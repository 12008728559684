import React, { useContext, useEffect, useRef, useState } from 'react';
import anime from 'animejs/lib/anime.es';
import { useTranslation } from 'react-i18next';
import * as style from './HeaderNavTablet.module.scss';
import { ReactComponent as BurgerOpenIcon } from '../../assets/vectors/Burger.svg';
import { ReactComponent as BurgerCloseIcon } from '../../assets/vectors/Burger-close.svg';
import { ReactComponent as ChevronLeftIcon } from '../../assets/vectors/Chevron-left.svg';
import { ReactComponent as ChevronDownIcon } from '../../assets/vectors/Chevron-down.svg';
import { getFlagFromCode, isObjectEmpty } from '../../utilities';
import { MainLayoutNavContext } from '../../contexts/MainLayoutNavContext';
import { getLanguageFromField } from '../../utilities/sharedUtils';
import { MainLayoutLocaleContext } from '../../contexts/MainLayoutLocaleContext';
import RidestoreLogoLink from '../RidestoreLogoLink';

const HeaderNavTablet = (props) => {
  const { shouldNavMenuTabletOpen, toggleNavMenuTablet } = props;

  const {
    primaryMenuToExpand,
    primaryMenuExpanded,
    secondaryMenuToExpand,
    secondaryMenuExpanded,
    navMenuAnimating,
    shouldLanguageMenuOpen,
    toggleLanguageMenu,
    setPrimaryMenuToExpand,
    setSecondaryMenuToExpand,
  } = useContext(MainLayoutNavContext);

  const { t } = useTranslation();
  const { locale } = useContext(MainLayoutLocaleContext);

  const headerWrapperRef = useRef();
  const [displayedHeader, setDisplayedHeader] = useState('main');

  useEffect(() => {
    if (
      primaryMenuToExpand?.childMenuItems?.length > 0 &&
      displayedHeader === 'main'
    ) {
      /**
       * Animate to the secondary/tertiary header if any primary menu item is expanded and header is now 'main'
       */
      anime({
        targets: headerWrapperRef.current,
        left: {
          value: '-100%',
          duration: '500',
          easing: 'easeInOutQuint',
          complete: () => {
            setDisplayedHeader('secondary');
          },
        },
      });
    } else if (
      isObjectEmpty(primaryMenuToExpand) &&
      displayedHeader === 'secondary'
    ) {
      /**
       * Animate to the main header if primary menu is collapsed and header is now 'secondary'
       */
      anime({
        targets: headerWrapperRef.current,
        left: {
          value: '0%',
          duration: '500',
          easing: 'easeInOutQuint',
          complete: () => {
            setDisplayedHeader('main');
          },
        },
      });
    }
  }, [primaryMenuToExpand]);

  const collapseAllMenus = () => {
    if (navMenuAnimating) return;
    setPrimaryMenuToExpand({});
    setSecondaryMenuToExpand({});
  };

  const collapseCurrentMenu = () => {
    if (navMenuAnimating) return;
    if (!isObjectEmpty(secondaryMenuToExpand)) {
      setSecondaryMenuToExpand({});
    } else if (!isObjectEmpty(primaryMenuToExpand)) {
      setPrimaryMenuToExpand({});
    }
  };

  useEffect(() => {
    if (shouldLanguageMenuOpen) {
      /**
       * Rotate chevron up
       */
      anime({
        targets: `.${style.languageChevronDown}`,
        rotate: {
          value: ['0', '180'],
          duration: '400',
          easing: 'easeInOutCubic',
        },
      });
    } else {
      /**
       * Rotate chevron down
       */
      anime({
        targets: `.${style.languageChevronDown}`,
        rotate: {
          value: '360',
          duration: '400',
          easing: 'easeInOutCubic',
        },
      });
    }
  }, [shouldLanguageMenuOpen]);

  const [flagComponent, setFlagComponent] = useState();

  useEffect(() => {
    setFlagComponent(
      React.cloneElement(
        getFlagFromCode(getLanguageFromField('locale', locale).countryCode),
        { width: '20', className: style.languageSwitchFlag }
      )
    );
  }, [locale]);

  return (
    <header className={style.header}>
      <div className={style.headerWrapper} ref={headerWrapperRef}>
        <div className={style.headerMain}>
          <div className={style.burgerContainer} title={t('labels.navMenu')}>
            {!shouldNavMenuTabletOpen ? (
              <BurgerOpenIcon
                className={style.iconHeader}
                alt="menu"
                onClick={toggleNavMenuTablet}
                width={20}
                height={20}
              />
            ) : (
              <BurgerCloseIcon
                className={style.iconHeader}
                alt="menu"
                onClick={toggleNavMenuTablet}
                width={20}
                height={20}
              />
            )}
          </div>
          <RidestoreLogoLink />
          <div className={style.languageOrSearchScroller}>
            <div className={style.languageOrSearch}>
              <div>
                <div
                  className={style.languageSwitch}
                  title={t('labels.changeLanguage')}
                  onClick={toggleLanguageMenu}
                >
                  {flagComponent}
                  <ChevronDownIcon
                    className={style.languageChevronDown}
                    width={20}
                    height={20}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.headerSecondary}>
          <ChevronLeftIcon
            className={style.iconSecondary}
            alt="menu"
            onClick={() => {
              collapseCurrentMenu();
            }}
            width={20}
            height={20}
          />
          <div className={style.secondaryTitle}>
            {secondaryMenuExpanded?.label || primaryMenuExpanded?.label}
          </div>
          <div className={style.horizontalSpacer} />
          <BurgerCloseIcon
            className={style.iconSecondary}
            alt="menu"
            width={20}
            height={20}
            onClick={() => {
              collapseAllMenus();
              toggleNavMenuTablet();
            }}
          />
        </div>
      </div>
    </header>
  );
};

export default HeaderNavTablet;
