import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as style from './CookieBanner.module.scss';

const CookieBanner = (props) => {
  const { onClose } = props;

  const { t } = useTranslation();

  return (
    <div className={`${style.cookieBanner} ${style.animated}`}>
      <div className={style.cookieBannerContents}>
        <span>{t('cookieBanner.bannerText')} </span>
        <a
          href={t('cookieBanner.link')}
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          {t('cookieBanner.readMore')}
        </a>
      </div>
      <svg
        baseProfile="tiny"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        width={16}
        height={16}
        className={style.closeIcon}
        onClick={onClose}
      >
        <path
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          d="M4.3 4.3l11.4 11.4M4.3 15.7L15.7 4.3"
        />
      </svg>
    </div>
  );
};

CookieBanner.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CookieBanner;
