import * as dayjs from 'dayjs';
import {
  DE,
  ES,
  FR,
  GB,
  US,
  IT,
  NL,
  DK,
  SE,
  FI,
} from 'country-flag-icons/react/3x2';
import React from 'react';
import { getLanguageFromField } from './sharedUtils';

const urljoin = require('url-join');
/**
 * Converting WordPress dateGmt '2021-03-03T13:39:09' to formatted string: 'Mar 3'
 * @param {string} datePostedGmt
 * @returns {string} - string like 'Oct 13'
 */
const datePostedGmtToString = (datePostedGmt) => {
  // Converting string to Date object ('Z' for GMT), then to local formatted string
  const dateObj = new Date(`${datePostedGmt}Z`);
  return dayjs(dateObj).format('MMM D');
};

/**
 * Removes all HTML tags from a string
 * @param {string} plainHtmlString - a string containing html tags
 * @returns {string} - a string cleared from <tags>
 */
const clearAllTagsFromString = (plainHtmlString) => {
  if (plainHtmlString) return plainHtmlString.replace(/(<([^>]+)>)/gi, '');
};

// https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
/**
 * Javascript implementation of Java’s String.hashCode() method
 * @param str {string} - a string
 * @returns {number} - a has code
 * @see {@link https://stackoverflow.com/a/8831937|StackOverflow}
 * @see {@link https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/|Manwe}
 */
const hashCode = (str) => {
  str = String(str);
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + char;
    // eslint-disable-next-line no-bitwise
    hash &= hash; // Convert to 32bit integer
  }
  return hash;
};

/**
 * Checks if object is empty or not
 * @param {Object} obj - supplied object
 * @returns {boolean}
 */
const isObjectEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};
/**
 * Get a rel attribute for a link based on the following data:
 * @param {string} linkTarget - a target attribute of a link (e.g. _blank)
 * @param {boolean} isNoFollowLink - whether the link is nofollow
 * @returns {string} - rel attribute
 */
const getRelAttribute = (linkTarget, isNoFollowLink) => {
  let rel = '';
  // Fixes vulnerability
  // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
  if (linkTarget && linkTarget !== '_self') {
    rel = 'noopener noreferrer';
  }
  if (isNoFollowLink) {
    rel += ' nofollow';
  }

  rel = rel.trim();

  return rel;
};

/**
 * Get Flag component (svg) from a country code
 * @param {string} countryCode - country code like 'GB', 'FR', 'SE', 'UA', 'JP', etc.
 * @returns Flag React element (svg)
 */
const isMagazine = process.env.GATSBY_RIDESTORE_ENVIRONMENT === 'magazine';
const isDope = process.env.GATSBY_RIDESTORE_ENVIRONMENT === 'dope';
const isMontec = process.env.GATSBY_RIDESTORE_ENVIRONMENT === 'montec';

const getFlagFromCode = (countryCode) => {
  switch (countryCode?.toUpperCase()) {
    case 'GB':
      if (isMagazine) return <GB alt={countryCode} />;
      if (isDope || isMontec) return <US alt={countryCode} />;
      break;
    case 'DE':
      return <DE alt={countryCode} />;
    case 'FI':
      return <FI alt={countryCode} />;
    case 'NL':
      return <NL alt={countryCode} />;
    case 'SE':
      return <SE alt={countryCode} />;
    case 'FR':
      return <FR alt={countryCode} />;
    case 'IT':
      return <IT alt={countryCode} />;
    case 'ES':
      return <ES alt={countryCode} />;
    case 'DK':
      return <DK alt={countryCode} />;
    default:
      if (isMagazine) return <GB alt={countryCode} />;
      if (isDope || isMontec) return <US alt={countryCode} />;
  }
};

const getLocaleFromI18n = (i18n) => {
  const { locale } = getLanguageFromField('i18nName', i18n.language).locale;
  return locale;
};

const getBioTextWithLocale = (multilingualBioArray, bioLocale) => {
  return (
    multilingualBioArray?.multilingualBio?.find(
      (bio) => bio.locale === bioLocale
    )?.bioText ?? multilingualBioArray?.multilingualBio?.[0]?.bioText
  );
};

const getYoutubeVideoIdFromUrl = (url) => {
  if (!url) return;
  const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;
  const match = url?.match(regExp);
  return match && match?.[1]?.length === 11 ? match[1] : false;
};

/**
 * Converts all falsy values to 0, and all truthy to 1
 * @param {any} value
 * @returns {number}
 */
const valueToBinaryLogic = (value) => {
  return value ? 1 : 0;
};

const blockHtmlScrolling = () => {
  if (typeof window === 'undefined') return;
  document.getElementsByTagName('html')[0].style.overflow = 'hidden';
};

const allowHtmlScrolling = () => {
  if (typeof window === 'undefined') return;
  document.getElementsByTagName('html')[0].style.overflow = 'auto';
};

const urlJoinSafe = (...urlBits) => {
  const urlBitsSafe = urlBits.map((urlBit) => urlBit ?? '');
  return urljoin(...urlBitsSafe);
};

export {
  datePostedGmtToString,
  clearAllTagsFromString,
  hashCode,
  isObjectEmpty,
  getRelAttribute,
  getFlagFromCode,
  getLocaleFromI18n,
  getBioTextWithLocale,
  getYoutubeVideoIdFromUrl,
  valueToBinaryLogic,
  blockHtmlScrolling,
  allowHtmlScrolling,
  urlJoinSafe,
};
