import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import anime from 'animejs/lib/anime.es';
import { useTranslation } from 'react-i18next';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as style from './HeaderDesktop.module.scss';
import { ReactComponent as ChevronDownIcon } from '../../assets/vectors/Chevron-down.svg';
import { ReactComponent as SearchIcon } from '../../assets/vectors/Search.svg';
import { getFlagFromCode } from '../../utilities';
import {
  getLanguageFromField,
  languages,
  filteredLanguages,
} from '../../utilities/sharedUtils';
import { MainLayoutLocaleContext } from '../../contexts/MainLayoutLocaleContext';
import { ReactComponent as ChevronRightIcon } from '../../assets/vectors/Chevron-right.svg';
import RidestoreLogoLink from '../RidestoreLogoLink';
import LanguageItem from '../LanguageItem';
import { CATEGORIES_URL } from '../../utilities/urlConstants';
import { pathJoinSafeSlashes } from '../../utilities/pathJoinSafe';

const HeaderDesktop = (props) => {
  const {
    topMenuHierarchy,
    openSearchMenu,
    setIsDesktopLanguageMenuAnimating,
    setIsNavMenuDesktopAnimating,
    isAnythingAnimating,
    setNewActiveLanguage,
    sustainability,
  } = props;

  const { t } = useTranslation();
  const { locale } = useContext(MainLayoutLocaleContext);

  // TODO: Animate secondary menu
  // TODO: Dynamically generate secondary menu
  const [shouldSecondaryNavOpen, setShouldSecondaryNavOpen] = useState(false);
  const [isSecondaryNavOpen, setIsSecondaryNavOpen] = useState(false);

  const [shouldLanguageMenuOpen, setShouldLanguageMenuOpen] = useState(false);
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

  useEffect(() => {
    if (isAnythingAnimating) return;
    if (!isLanguageMenuOpen && shouldLanguageMenuOpen) {
      /**
       * Rotate chevron up
       */
      const timelineOpen = anime.timeline({
        begin() {
          setShouldSecondaryNavOpen(false);
          setIsDesktopLanguageMenuAnimating(true);
          anime.set('#desktopLanguageMenuOverlay', { display: 'block' });
        },
        complete() {
          setIsLanguageMenuOpen(true);
          setIsDesktopLanguageMenuAnimating(false);
        },
      });
      timelineOpen.add(
        {
          targets: '#desktopLanguageMenuOverlay',
          opacity: {
            value: ['0', '1'],
            duration: '400',
            easing: 'easeInOutCubic',
          },
        },
        0
      );
      timelineOpen.add(
        {
          targets: '#desktopLanguageMenu',
          translateY: {
            value: ['-40px', '0px'],
            duration: '400',
            easing: 'easeInOutCubic',
          },
        },
        0
      );
      timelineOpen.add(
        {
          targets: '#desktopLanguageMenuChevron',
          rotate: {
            value: ['0', '180'],
            duration: '400',
            easing: 'easeInOutCubic',
          },
        },
        0
      );
      return;
    }
    if (isLanguageMenuOpen && !shouldLanguageMenuOpen) {
      /**
       * Rotate chevron down
       */
      const timelineOpen = anime.timeline({
        begin() {
          setIsDesktopLanguageMenuAnimating(true);
        },
        complete() {
          anime.set('#desktopLanguageMenuOverlay', { display: 'none' });
          setIsLanguageMenuOpen(false);
          setIsDesktopLanguageMenuAnimating(false);
        },
      });
      timelineOpen.add(
        {
          targets: '#desktopLanguageMenuOverlay',
          opacity: {
            value: ['1', '0'],
            duration: '400',
            easing: 'easeInOutCubic',
          },
        },
        0
      );
      timelineOpen.add(
        {
          targets: '#desktopLanguageMenu',
          translateY: {
            value: ['0px', '-40px'],
            duration: '400',
            easing: 'easeInOutCubic',
          },
        },
        0
      );
      timelineOpen.add(
        {
          targets: '#desktopLanguageMenuChevron',
          rotate: {
            value: '360',
            duration: '400',
            easing: 'easeInOutCubic',
          },
        },
        0
      );
    }
  }, [isLanguageMenuOpen, shouldLanguageMenuOpen]);

  useEffect(() => {
    if (isAnythingAnimating) return;
    if (!isSecondaryNavOpen && shouldSecondaryNavOpen) {
      const timelineOpen = anime.timeline({
        begin() {
          setShouldLanguageMenuOpen(false);
          setIsNavMenuDesktopAnimating(true);
          anime.set('#desktopSecondaryNavOverlay', { display: 'block' });
        },
        complete() {
          setIsSecondaryNavOpen(true);
          setIsNavMenuDesktopAnimating(false);
        },
      });
      timelineOpen.add(
        {
          targets: '#desktopSecondaryNavOverlay',
          opacity: {
            value: ['0', '1'],
            duration: '400',
            easing: 'easeInOutCubic',
          },
        },
        0
      );
      timelineOpen.add(
        {
          targets: '#desktopSecondaryNav',
          translateY: {
            value: ['-40px', '0px'],
            duration: '400',
            easing: 'easeInOutCubic',
          },
        },
        0
      );
      timelineOpen.add(
        {
          targets: '#desktopNavChevron',
          rotateX: {
            value: ['0', '180'],
            duration: '400',
            easing: 'easeInOutCubic',
          },
        },
        0
      );
      return;
    }
    if (isSecondaryNavOpen && !shouldSecondaryNavOpen) {
      /**
       * Rotate chevron down
       */
      const timelineOpen = anime.timeline({
        begin() {
          setIsNavMenuDesktopAnimating(true);
        },
        complete() {
          anime.set('#desktopSecondaryNavOverlay', { display: 'none' });
          setIsSecondaryNavOpen(false);
          setIsNavMenuDesktopAnimating(false);
        },
      });
      timelineOpen.add(
        {
          targets: '#desktopSecondaryNavOverlay',
          opacity: {
            value: ['1', '0'],
            duration: '400',
            easing: 'easeInOutCubic',
          },
        },
        0
      );
      timelineOpen.add(
        {
          targets: '#desktopSecondaryNav',
          translateY: {
            value: ['0px', '-40px'],
            duration: '400',
            easing: 'easeInOutCubic',
          },
        },
        0
      );
      timelineOpen.add(
        {
          targets: '#desktopNavChevron',
          rotateX: {
            value: '0',
            duration: '400',
            easing: 'easeInOutCubic',
          },
        },
        0
      );
    }
  }, [isSecondaryNavOpen, shouldSecondaryNavOpen]);

  const [flagComponent, setFlagComponent] = useState();

  useEffect(() => {
    setFlagComponent(
      React.cloneElement(
        getFlagFromCode(getLanguageFromField('locale', locale).countryCode),
        { width: '20', className: style.languageSwitchFlag }
      )
    );
  }, [locale]);

  const secondaryMenu = topMenuHierarchy?.[0]?.childMenuItems;

  const isMagazine = process.env.GATSBY_RIDESTORE_ENVIRONMENT === 'magazine';
  const isDope = process.env.GATSBY_RIDESTORE_ENVIRONMENT === 'dope';
  const isMontec = process.env.GATSBY_RIDESTORE_ENVIRONMENT === 'montec';

  return (
    <>
      <header
        className={`${style.header} ${
          sustainability ? style.sustainability : ''
        }`}
      >
        <div className={style.headerMain}>
          <RidestoreLogoLink
            className={style.ridestoreLogoDesktop}
            sustainability={sustainability}
          />
          {!sustainability && (
            <div className={style.navMenuPrimary}>
              {topMenuHierarchy?.map((menuItem) =>
                menuItem?.childMenuItems?.length ? (
                  <div
                    className={style.navMenuPrimaryItem}
                    key={menuItem.id}
                    onClick={() => {
                      setShouldSecondaryNavOpen((prevState) => !prevState);
                    }}
                  >
                    <span>{menuItem?.label}</span>
                    <ChevronDownIcon
                      id="desktopNavChevron"
                      className={style.navMenuChevronDown}
                      width={20}
                      height={20}
                    />
                  </div>
                ) : (
                  <Link
                    className={`clearLinkStyle d-block ${style.navMenuPrimaryItem}`}
                    to={pathJoinSafeSlashes(
                      CATEGORIES_URL,
                      menuItem?.connectedNode?.node?.slug
                    )}
                    key={menuItem.id}
                  >
                    {menuItem?.label}
                  </Link>
                )
              )}
            </div>
          )}
          <div className={style.languageAndSearch}>
            <div title={t('labels.search')}>
              <SearchIcon
                className={style.searchIcon}
                alt="search"
                onClick={() => {
                  openSearchMenu();
                }}
                width={20}
                height={20}
              />
            </div>
            {!sustainability && (
              <div>
                <div
                  className={style.languageSwitch}
                  title={t('labels.changeLanguage')}
                  onClick={() => {
                    setShouldLanguageMenuOpen((prevState) => !prevState);
                  }}
                >
                  {flagComponent}
                  <ChevronDownIcon
                    className={style.languageChevronDown}
                    id="desktopLanguageMenuChevron"
                    width={20}
                    height={20}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </header>

      {/* Secondary nav menu */}

      <div
        className={style.navOverlay}
        id="desktopSecondaryNavOverlay"
        onClick={() => {
          setShouldSecondaryNavOpen(false);
        }}
      >
        <nav
          className={style.navSecondaryDesktop}
          id="desktopSecondaryNav"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {secondaryMenu?.map((secondaryMenuItem) => {
            const imageData =
              secondaryMenuItem?.MenuItemImages?.menuItemImages?.imgixImage
                ?.gatsbyImageData;

            return (
              <div
                className={style.navSecondaryItem}
                key={secondaryMenuItem.id}
              >
                <div className={style.navSecondaryItemLabel}>
                  {secondaryMenuItem.label}
                </div>
                {imageData?.images && (
                  <GatsbyImage
                    className={style.navSecondaryItemImage}
                    image={imageData}
                    alt={secondaryMenuItem.label}
                  />
                )}
                <div className={style.navTertiary}>
                  {secondaryMenuItem?.childMenuItems?.map((tertiaryItem) => {
                    const imageDataSm =
                      tertiaryItem?.MenuItemImages?.menuItemImages?.imgixImage
                        ?.gatsbyImageData_sm;

                    return (
                      <Link
                        className={`clearLinkStyle d-block ${style.navTertiaryItem}`}
                        key={tertiaryItem.id}
                        to={pathJoinSafeSlashes(
                          tertiaryItem?.connectedNode?.node?.slug
                        )}
                      >
                        {imageDataSm?.images && (
                          <GatsbyImage
                            image={imageDataSm}
                            className={style.navTertiaryItemImage}
                            alt="section cover"
                          />
                        )}
                        <div className={style.navTertiaryItemLabel}>
                          {tertiaryItem.label}
                        </div>
                        <div className={style.horizontalSpacer} />
                        <ChevronRightIcon width={20} height={20} />
                      </Link>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </nav>
      </div>

      {/* Language switch */}

      <div
        className={style.languageMenuOverlay}
        id="desktopLanguageMenuOverlay"
        onClick={() => {
          setShouldLanguageMenuOpen(false);
        }}
      >
        <div className={style.languageMenuContainer}>
          <div
            className={style.languageMenu}
            id="desktopLanguageMenu"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className={style.languageMenuTitle}>
              {t('languageMenu.language')}
            </div>
            <>
              {isMagazine && (
                <>
                  {languages?.map((language) => (
                    <LanguageItem
                      key={language?.countryCode}
                      name={language?.name}
                      countryCode={language?.countryCode}
                      locale={language?.locale}
                      langSlug={pathJoinSafeSlashes(language?.langSlug)}
                      className="languageItemDesktopAnimate"
                      setNewActiveLanguage={setNewActiveLanguage}
                    />
                  ))}
                </>
              )}
              {(isDope || isMontec) && (
                <>
                  {filteredLanguages?.map((language) => (
                    <LanguageItem
                      key={language?.countryCode}
                      name={language?.name}
                      countryCode={language?.countryCode}
                      locale={language?.locale}
                      langSlug={pathJoinSafeSlashes(language?.langSlug)}
                      className="languageItemDesktopAnimate"
                      setNewActiveLanguage={setNewActiveLanguage}
                    />
                  ))}
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderDesktop;
