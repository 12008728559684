import React, { useContext, useEffect, useRef } from 'react';
import anime from 'animejs/lib/anime.es';
import { useTranslation } from 'react-i18next';
import * as style from './LanguageMenu.module.scss';
import { languages } from '../../utilities/sharedUtils';
import LanguageItem from '../LanguageItem';
import { pathJoinSafeSlashes } from '../../utilities/pathJoinSafe';
import { MainLayoutNavContext } from '../../contexts/MainLayoutNavContext';

const LanguageMenu = (props) => {
  const { setIsLanguageMenuAnimating, setNewActiveLanguage } = props;

  const { shouldLanguageMenuOpen } = useContext(MainLayoutNavContext);

  const { t } = useTranslation();

  const languageMenuRef = useRef();

  useEffect(() => {
    if (shouldLanguageMenuOpen) {
      const timeline = anime.timeline({
        begin() {
          anime.set(languageMenuRef.current, { display: 'flex' });
        },
        complete() {
          setIsLanguageMenuAnimating(false);
        },
      });
      /**
       * Language menu animation
       */
      timeline.add({
        targets: languageMenuRef.current,
        opacity: {
          value: 1,
          duration: 300,
          easing: 'easeInOutCubic',
        },
      });
      /**
       * Language items animation
       */
      timeline.add({
        targets: '.languageItemAnimation',
        opacity: {
          value: [0, 1],
          duration: 300,
          easing: 'easeInCubic',
          delay: anime.stagger(50),
        },
        translateY: {
          value: ['70px', '0px'],
          duration: 300,
          easing: 'easeOutCubic',
          delay: anime.stagger(50),
        },
      });
    } else {
      anime({
        targets: languageMenuRef.current,
        opacity: {
          value: 0,
          duration: 200,
          easing: 'easeInCubic',
        },
        complete() {
          setIsLanguageMenuAnimating(false);
          anime.set(languageMenuRef.current, { display: 'none' });
        },
      });
    }
  }, [shouldLanguageMenuOpen]);

  return (
    <div className={style.languageMenu} ref={languageMenuRef}>
      <div className={style.heading}>{t('languageMenu.language')}</div>
      <div className={style.languageList}>
        {languages?.map((language) => (
          <LanguageItem
            key={language?.countryCode}
            name={language?.name}
            className="languageItemAnimation"
            countryCode={language?.countryCode}
            locale={language?.locale}
            langSlug={pathJoinSafeSlashes(language?.langSlug)}
            setNewActiveLanguage={setNewActiveLanguage}
          />
        ))}
      </div>
    </div>
  );
};

LanguageMenu.defaultProps = {
  className: '',
};

LanguageMenu.propTypes = {};

export default LanguageMenu;
