import React, { useContext, useEffect, useRef, useState } from 'react';
import anime from 'animejs/lib/anime.es';
import { MainLayoutNavContext } from '../../contexts/MainLayoutNavContext';
import * as style from './NavMenuTablet.module.scss';
import NavMenu from '../NavMenu/NavMenu';
import HeaderNavTablet from '../HeaderNavTablet';

const NavMenuTablet = (props) => {
  const {
    shouldNavMenuTabletOpen,
    toggleNavMenuTablet,
    topMenuHierarchy,
    bottomMenu,
    socialMenu,
  } = props;

  const { setNavMenuAnimating } = useContext(MainLayoutNavContext);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const navTabletWrapper = useRef();
  const navTabletBackdrop = useRef();

  const animate = (isOpenAnimation) => {
    if (typeof window === 'undefined') return;
    if (isOpenAnimation) {
      const t = anime.timeline({
        begin() {
          setIsNavOpen(true);
        },
        complete() {
          setNavMenuAnimating(false);
        },
      });

      t.add(
        {
          targets: navTabletWrapper.current,
          left: {
            value: '0',
            easing: 'easeOutQuart',
            duration: 500,
          },
        },
        0
      );
      t.add(
        {
          targets: navTabletBackdrop.current,
          opacity: {
            value: '1',
            easing: 'easeOutQuart',
            duration: 500,
          },
        },
        0
      );
    } else {
      const t = anime.timeline({
        complete() {
          setIsNavOpen(false);
          setNavMenuAnimating(false);
        },
      });
      t.add(
        {
          targets: navTabletWrapper.current,
          left: {
            value: '-100%',
            easing: 'easeInCubic',
            duration: 500,
          },
        },
        0
      );
      t.add(
        {
          targets: navTabletBackdrop.current,
          opacity: {
            value: '0',
            easing: 'easeInCubic',
            duration: 300,
          },
        },
        0
      );
    }
  };

  useEffect(() => {
    if (isNavOpen !== shouldNavMenuTabletOpen) {
      animate(shouldNavMenuTabletOpen);
    }
  }, [shouldNavMenuTabletOpen]);

  return (
    <>
      <div
        ref={navTabletWrapper}
        className={style.navTabletWrapper}
        style={{ display: isNavOpen ? 'flex' : 'none' }}
      >
        <HeaderNavTablet
          shouldNavMenuTabletOpen={shouldNavMenuTabletOpen}
          toggleNavMenuTablet={toggleNavMenuTablet}
        />
        <NavMenu
          topMenuHierarchy={topMenuHierarchy}
          bottomMenu={bottomMenu}
          socialMenu={socialMenu}
          variant="tablet"
        />
      </div>
      <div
        ref={navTabletBackdrop}
        className={style.navTabletBackdrop}
        style={{ display: isNavOpen ? 'flex' : 'none' }}
        onClick={toggleNavMenuTablet}
      />
    </>
  );
};

export default NavMenuTablet;
