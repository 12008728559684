import React from 'react';

import { ReactComponent as InstagramIconBlack } from '../assets/vectors/Instagram-black.svg';
import { ReactComponent as FacebookIconBlack } from '../assets/vectors/Facebook-black.svg';

const getIconComponentFromIconName = (iconName) => {
  switch (iconName) {
    case 'Instagram':
    case 'InstagramBlack':
    default:
      return <InstagramIconBlack width={20} height={20} />;
    case 'Facebook':
    case 'FacebookBlack':
      return <FacebookIconBlack width={20} height={20} />;
    case 'InstagramWhite':
      return (
        <InstagramIconBlack width={20} height={20} className="iconWhite" />
      );
    case 'FacebookWhite':
      return <FacebookIconBlack width={20} height={20} className="iconWhite" />;
  }
};

export default getIconComponentFromIconName;
