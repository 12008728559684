// extracted by mini-css-extract-plugin
export var header = "HeaderDesktop-module--header--QtHfQ";
export var sustainability = "HeaderDesktop-module--sustainability--3RgGT";
export var headerMain = "HeaderDesktop-module--headerMain--2GjS0";
export var searchIcon = "HeaderDesktop-module--searchIcon--3oJUG";
export var horizontalSpacer = "HeaderDesktop-module--horizontalSpacer--20Du-";
export var burgerContainer = "HeaderDesktop-module--burgerContainer--2FrIp";
export var languageAndSearch = "HeaderDesktop-module--languageAndSearch--3BUF-";
export var languageSwitch = "HeaderDesktop-module--languageSwitch--3dmOO";
export var languageSwitchFlag = "HeaderDesktop-module--languageSwitchFlag--2GTig";
export var languageChevronDown = "HeaderDesktop-module--languageChevronDown--21LU_";
export var ridestoreLogoDesktop = "HeaderDesktop-module--ridestoreLogoDesktop--LRYm3";
export var navMenuPrimary = "HeaderDesktop-module--navMenuPrimary--G3oxI";
export var navMenuPrimaryItem = "HeaderDesktop-module--navMenuPrimaryItem--12M5S";
export var navMenuChevronDown = "HeaderDesktop-module--navMenuChevronDown--28VOe";
export var navOverlay = "HeaderDesktop-module--navOverlay--233M-";
export var navSecondaryDesktop = "HeaderDesktop-module--navSecondaryDesktop--3ki0x";
export var navSecondaryItem = "HeaderDesktop-module--navSecondaryItem--2xlWQ";
export var navSecondaryItemLabel = "HeaderDesktop-module--navSecondaryItemLabel--zl25O";
export var navSecondaryItemImage = "HeaderDesktop-module--navSecondaryItemImage--kavx-";
export var navTertiary = "HeaderDesktop-module--navTertiary--2IaRl";
export var navTertiaryItem = "HeaderDesktop-module--navTertiaryItem--YcUSJ";
export var navTertiaryItemImage = "HeaderDesktop-module--navTertiaryItemImage--1tldZ";
export var navTertiaryItemLabel = "HeaderDesktop-module--navTertiaryItemLabel--2yWY-";
export var languageMenuOverlay = "HeaderDesktop-module--languageMenuOverlay--1FIXX";
export var languageMenuContainer = "HeaderDesktop-module--languageMenuContainer--2vQUe";
export var languageMenu = "HeaderDesktop-module--languageMenu--3HXrL";
export var languageMenuTitle = "HeaderDesktop-module--languageMenuTitle--dh8M0";