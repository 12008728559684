/**
 * Leading slash
 * @type {string}
 */
const LS = '/';
/**
 * Trailing slash
 * @type {string}
 */
const TS = '/';

const CATEGORIES_URL = '';
const AUTHOR_URL = 'author';

module.exports.TS = TS;
module.exports.LS = LS;
module.exports.CATEGORIES_URL = CATEGORIES_URL;
module.exports.AUTHOR_URL = AUTHOR_URL;
