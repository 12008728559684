import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import * as style from './RidestoreLogoLink.module.scss';
import { ReactComponent as RidestoreLogo } from '../../assets/vectors/Logo.svg';
import { ReactComponent as DopesnowLogo } from '../../assets/vectors/Dopesnow-logo.svg';
import { ReactComponent as MontecLogo } from '../../assets/vectors/Montec-logo.svg';

const RidestoreLogoLink = (props) => {
  const { className, sustainability, ...rest } = props;

  const { t } = useTranslation();

  const isMagazine = process.env.GATSBY_RIDESTORE_ENVIRONMENT === 'magazine';
  const isDope = process.env.GATSBY_RIDESTORE_ENVIRONMENT === 'dope';
  const isMontec = process.env.GATSBY_RIDESTORE_ENVIRONMENT === 'montec';

  return (
    <>
      {isMagazine && (
        <Link
          {...rest}
          to="/"
          className={`${style.logoWrapper} clearLinkStyle d-block ${className}`}
          title={t('labels.ridestoreMagazine')}
        >
          <>
            <RidestoreLogo alt="logo" width={103} height={25} />
            {!sustainability && (
              <div className={style.logoSubScript}>MAGAZINE</div>
            )}
          </>
        </Link>
      )}
      {isDope && (
        <Link
          {...rest}
          className={`${style.logoWrapper} clearLinkStyle d-block ${className}`}
          to="/"
          title={t('labels.dopeBack')}
        >
          <DopesnowLogo alt="logo" width={60} height={20} />
          {!sustainability && (
            <div className={style.logoSubScript}>MAGAZINE</div>
          )}
        </Link>
      )}
      {isMontec && (
        <Link
          {...rest}
          className={`${style.logoWrapper} clearLinkStyle d-block ${className}`}
          to="/"
          title={t('labels.montecBack')}
        >
          <MontecLogo alt="logo" width={109} height={21} />
          {!sustainability && (
            <div className={style.logoSubScript}>MAGAZINE</div>
          )}
        </Link>
      )}
    </>
  );
};
RidestoreLogoLink.defaultProps = {
  className: '',
};

export default RidestoreLogoLink;
