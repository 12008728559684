// extracted by mini-css-extract-plugin
export var header = "Header-module--header--1Qt4T";
export var headerWrapper = "Header-module--headerWrapper--1oGfD";
export var logoOnly = "Header-module--logoOnly--35mp7";
export var headerMain = "Header-module--headerMain--20poc";
export var iconHeader = "Header-module--iconHeader--1i3O5";
export var horizontalSpacer = "Header-module--horizontalSpacer--3fgEb";
export var burgerContainer = "Header-module--burgerContainer--1NTaf";
export var burgerContainerTablet = "Header-module--burgerContainerTablet--NCSqd";
export var languageOrSearchScroller = "Header-module--languageOrSearchScroller--28_ov";
export var languageOrSearch = "Header-module--languageOrSearch--MhDXk";
export var languageSwitch = "Header-module--languageSwitch--2lgKu";
export var languageSwitchFlag = "Header-module--languageSwitchFlag--VNog2";
export var languageChevronDown = "Header-module--languageChevronDown--3NDJS";
export var ridestoreLogo = "Header-module--ridestoreLogo--Vb8_j";
export var headerSecondary = "Header-module--headerSecondary--3baFX";
export var secondaryTitle = "Header-module--secondaryTitle--82fVd";
export var iconSecondary = "Header-module--iconSecondary--3p7pS";
export var sustainability = "Header-module--sustainability--2Zh3x";