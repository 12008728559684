const genericLangObject = {
  locale: 'en_GB',
  i18nLocale: 'en-GB',
  langSlug: 'eu',
  i18nName: 'en',
  name: 'English',
  languageCode: 'EN',
  countryCode: 'GB',
  featuredPostsTagId: 'dGVybTo4Mg==',
  wpHomeSlug: 'home-eu',
  languageSiteUrl: process.env.GATSBY_SITE_URL_EN,
  topMenuId: 'dGVybTo2Mw==',
  bottomMenuId: 'dGVybTo2NA==',
  footerId: 'dGVybTo0NTE=',
};
// TODO: flag code
const englishLangObj = {
  locale: 'en_GB',
  i18nLocale: 'en-GB',
  langSlug: '',
  i18nName: 'en',
  name: 'English',
  languageCode: 'EN',
  countryCode: 'GB',
  featuredPostsTagId: 'dGVybTo4Mg==',
  wpHomeSlug: 'home',
  languageSiteUrl: process.env.GATSBY_SITE_URL_EN,
  topMenuId: 'dGVybTo2Mw==',
  bottomMenuId: 'dGVybTo2NA==',
  footerId: 'dGVybTo0NTE=',
};

const languages = [
  englishLangObj,
  {
    locale: 'da_DK',
    langSlug: 'da',
    i18nLocale: 'da-DK',
    i18nName: 'da',
    name: 'Dansk',
    countryCode: 'DK',
    languageCode: 'DA',
    featuredPostsTagId: 'dGVybToxNjAzNw==',
    wpHomeSlug: 'danish-home',
    languageSiteUrl: process.env.GATSBY_SITE_URL_DA,
    topMenuId: 'dGVybToxNjM0MQ==',
    bottomMenuId: 'dGVybToxNjU1NQ==',
    footerId: 'dGVybToxNjQ2Mg==',
  },
  {
    locale: 'de_DE',
    i18nLocale: 'de-DE',
    langSlug: 'de',
    i18nName: 'de',
    name: 'Deutsch',
    countryCode: 'DE',
    languageCode: 'DE',
    featuredPostsTagId: 'dGVybToyODc=',
    wpHomeSlug: 'home-deutsch',
    languageSiteUrl: process.env.GATSBY_SITE_URL_DE,
    topMenuId: 'dGVybToyMjQ=',
    bottomMenuId: 'dGVybToyMzc=',
    footerId: 'dGVybTo4NTQ=',
  },
  {
    locale: 'es_ES',
    langSlug: 'es',
    i18nLocale: 'es-ES',
    i18nName: 'es',
    name: 'Español',
    countryCode: 'ES',
    languageCode: 'ES',
    featuredPostsTagId: 'dGVybTo1MzM2Mw==',
    wpHomeSlug: 'spanish-home',
    languageSiteUrl: process.env.GATSBY_SITE_URL_ES,
    topMenuId: 'dGVybTo0NTkwNg==',
    bottomMenuId: 'dGVybTo0NTk4Mw==',
    footerId: 'dGVybTo0NTk5MA==',
  },
  {
    locale: 'fr_FR',
    langSlug: 'fr',
    i18nLocale: 'fr-FR',
    i18nName: 'fr',
    name: 'Français',
    countryCode: 'FR',
    languageCode: 'FR',
    featuredPostsTagId: 'dGVybToxNjAxNw==',
    wpHomeSlug: 'french-home',
    languageSiteUrl: process.env.GATSBY_SITE_URL_FR,
    topMenuId: 'dGVybToxNjMwMA==',
    bottomMenuId: 'dGVybToxNjUzMA==',
    footerId: 'dGVybToxNjM3Mw==',
  },
  {
    locale: 'it_IT',
    langSlug: 'it',
    i18nLocale: 'it-IT',
    i18nName: 'it',
    name: 'Italiano',
    countryCode: 'IT',
    languageCode: 'IT',
    featuredPostsTagId: 'dGVybToxNjAyMQ==',
    wpHomeSlug: 'italian-home',
    languageSiteUrl: process.env.GATSBY_SITE_URL_IT,
    topMenuId: 'dGVybToxNjMxMQ==',
    bottomMenuId: 'dGVybToxNjUzNg==',
    footerId: 'dGVybToxNjQxNw==',
  },
  {
    locale: 'nl_NL',
    langSlug: 'nl',
    i18nLocale: 'nl-NL',
    i18nName: 'nl',
    name: 'Nederlands',
    countryCode: 'NL',
    languageCode: 'NL',
    featuredPostsTagId: 'dGVybToxNjAyOQ==',
    wpHomeSlug: 'netherlands-home',
    languageSiteUrl: process.env.GATSBY_SITE_URL_NL,
    topMenuId: 'dGVybToxNjMyMQ==',
    bottomMenuId: 'dGVybToxNjU0Mg==',
    footerId: 'dGVybToxNjM5NQ==',
  },
  {
    locale: 'fi',
    langSlug: 'fi',
    i18nLocale: 'fi',
    i18nName: 'fi',
    name: 'Suomi',
    countryCode: 'FI',
    languageCode: 'FI',
    featuredPostsTagId: 'dGVybToxNjAzMw==',
    wpHomeSlug: 'finnish-home',
    languageSiteUrl: process.env.GATSBY_SITE_URL_FI,
    topMenuId: 'dGVybToxNjMzMQ==',
    bottomMenuId: 'dGVybToxNjU0OQ==',
    footerId: 'dGVybToxNjQzOQ==',
  },
  {
    locale: 'sv_SE',
    langSlug: 'sv',
    i18nLocale: 'sv-SE',
    i18nName: 'sv',
    name: 'Svenska',
    countryCode: 'SE',
    languageCode: 'SV',
    featuredPostsTagId: 'dGVybToxNjAyNQ==',
    wpHomeSlug: 'swedish-home',
    languageSiteUrl: process.env.GATSBY_SITE_URL_SE,
    topMenuId: 'dGVybToxNjI5MA==',
    bottomMenuId: 'dGVybToxNjUyMw==',
    footerId: 'dGVybToxNjM1MQ==',
  },
];

// For Dope & Montec magazine
const filteredLanguages = languages.filter(
  (language) => language.countryCode !== 'ES' && language.countryCode !== 'DK'
);

/**
 * Get language object filtered by a property
 * @param {'locale'|'langSlug'|'countryCode'|'languageCode'|'wpHomeSlug'|'i18nName'|'languageSiteUrl'|'i18nLocale'} field
 * @param equalTo
 * @returns {{wpHomeSlug: string, i18nName: string, countryCode: string, languageCode: string, name: string,
 * langSlug: string, locale: string, featuredPostsTagId: string, languageSiteUrl: string | undefined, i18nLocale: string,
 * topMenuId: string, bottomMenuId: string, footerId: string}}
 */

const isMagazine = process.env.GATSBY_RIDESTORE_ENVIRONMENT === 'magazine';
const isDope = process.env.GATSBY_RIDESTORE_ENVIRONMENT === 'dope';
const isMontec = process.env.GATSBY_RIDESTORE_ENVIRONMENT === 'montec';

const getLanguageFromField = (field, equalTo) => {
  if (isMagazine)
    return (
      languages.find((language) => language[field] === equalTo) ||
      genericLangObject
    );
  if (isDope || isMontec)
    return (
      filteredLanguages.find((language) => language[field] === equalTo) ||
      genericLangObject
    );
};

/**
 *
 * @param locale
 * @returns {string}
 */
const getI18nCodeFromLocale = (locale) => {
  return getLanguageFromField('locale', locale).i18nName;
};

const leadingSlash = (langSlug) => {
  return langSlug ? `/${langSlug}` : `${langSlug}`;
};

const excludeTags = process.env.GATSBY_EXCLUDE_TAGS
  ? process.env.GATSBY_EXCLUDE_TAGS.split(',')
  : [];
let excludeTagSlugs = excludeTags.flatMap((x) =>
  languages.map((y) => `${x}-${y.i18nName}`)
);
excludeTagSlugs = excludeTagSlugs.length ? excludeTagSlugs : null;

const includeTags = process.env.GATSBY_INCLUDE_TAGS
  ? process.env.GATSBY_INCLUDE_TAGS.split(',')
  : [];
let includeTagSlugs = includeTags.flatMap((x) =>
  languages.map((y) => `${x}-${y.i18nName}`)
);
includeTagSlugs = includeTagSlugs.length ? includeTagSlugs : null;

exports.languages = languages;
exports.filteredLanguages = filteredLanguages;
exports.getLanguageFromField = getLanguageFromField;
exports.getI18nCodeFromLocale = getI18nCodeFromLocale;
exports.leadingSlash = leadingSlash;
exports.excludeTagSlugs = excludeTagSlugs;
exports.includeTagSlugs = includeTagSlugs;
