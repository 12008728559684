import { LS, TS } from './urlConstants';

const path = require('path-browserify');

export const pathJoinSafe = (...parts) => {
  if (!parts || !parts?.length) return path.join();

  const partsSafe = parts?.map((part) => part?.toString() ?? '');

  return path.join(...partsSafe);
};

export const pathJoinSafeSlashes = (...parts) => {
  if (!parts || !parts?.length) return path.join();

  const partsSafe = parts?.map((part) => part?.toString() ?? '');

  return path.join(LS, ...partsSafe, TS);
};
