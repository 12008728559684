import React, { useContext, useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import anime from 'animejs/lib/anime.es';
import {
  InstantSearch,
  Configure,
  connectSearchBox,
  connectStateResults,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import { useTranslation } from 'react-i18next';
import * as style from './SearchMenu.module.scss';
import ListItemPost from '../ListItemPost';
import { ReactComponent as SearchIcon } from '../../assets/vectors/Search.svg';
import { ReactComponent as BurgerCloseIcon } from '../../assets/vectors/Burger-close.svg';
import { MainLayoutLocaleContext } from '../../contexts/MainLayoutLocaleContext';
import RidestoreLogoLink from '../RidestoreLogoLink';
import { pathJoinSafeSlashes } from '../../utilities/pathJoinSafe';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

const ConnectedSearchBox = connectSearchBox(
  ({ refine, currentRefinement, inputRef, onFocus, t }) => (
    <div className={style.searchInputWrapper}>
      <SearchIcon className={style.searchIcon} width={20} height={20} />
      <input
        type="text"
        placeholder={t('search.searchAnything')}
        className={style.searchInput}
        onChange={(e) => refine(e.target.value)}
        ref={inputRef}
        value={currentRefinement}
        onFocus={onFocus}
      />
    </div>
  )
);

// TODO: same page search
const SearchMenu = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        ph10: file(relativePath: { eq: "images/ph-10.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 400
              quality: 50
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              breakpoints: [200, 330, 400]
              sizes: "(max-width: 768px) 110px, 200px"
            )
          }
        }
      }
    `
  );

  const {
    shouldSearchMenuOpen,
    setShouldSearchMenuOpen,
    setIsSearchMenuAnimating,
    sustainability = false,
  } = props;

  const { t } = useTranslation();

  const { locale } = useContext(MainLayoutLocaleContext);

  const searchMenuRef = useRef();
  const searchInputRef = useRef();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (shouldSearchMenuOpen) {
        setIsSearchMenuAnimating(true);
        anime({
          begin() {
            anime.set(searchMenuRef.current, { display: 'flex' });
          },
          targets: searchMenuRef.current,
          opacity: {
            value: 1,
            easing: 'easeOutCubic',
            duration: 500,
          },
          top: {
            value: ['-10%', '0%'],
            easing: 'easeOutCubic',
            duration: 500,
          },
          // Animated backdrop blur effect heavy on performance no matter just 28 updates
          // update(anim) {
          //   searchMenu.current.style.backdropFilter = `blur(${
          //     (anim.progress / 100) * 16
          //   }px)`;
          // },
          autoplay: true,
          complete() {
            setIsSearchMenuAnimating(false);
            searchInputRef.current?.focus();
          },
        });
      } else if (!shouldSearchMenuOpen) {
        setIsSearchMenuAnimating(true);
        anime({
          begin() {
            //
          },
          targets: searchMenuRef.current,
          opacity: {
            value: 0,
            easing: 'easeInCubic',
            duration: 350,
          },
          top: {
            value: ['0%', '-10%'],
            easing: 'easeInCubic',
            duration: 350,
          },
          autoplay: true,
          // Animated backdrop blur effect heavy on performance no matter just 28 updates
          // update(anim) {
          //   searchMenu.current.style.backdropFilter = `blur(${
          //     ((100 - anim.progress) / 100) * 16
          //   }px)`;
          // },
          complete() {
            setIsSearchMenuAnimating(false);
            anime.set(searchMenuRef.current, { display: 'none' });
          },
        });
      }
    }
  }, [shouldSearchMenuOpen]);

  const ConnectedSearchResults = connectStateResults(
    ({ searchResults, placeholderImage, searching }) => {
      const hits = searchResults?.hits.filter((hit) =>
        hit?.categories?.some((category) => {
          if (sustainability) {
            return category.slug === 'sustainability';
          } else {
            return category.slug !== 'sustainability';
          }
        })
      );

      return (
        <div className={style.postsWrapper}>
          {hits?.map((hit) => (
            <ListItemPost
              key={hit?.objectID}
              slug={pathJoinSafeSlashes(hit?.slug)}
              featuredImgDataMobile={
                hit?.featuredImage?.node?.imgixImageMobile?.gatsbyImageData ||
                placeholderImage
              }
              featuredImgData={
                hit?.featuredImage?.node?.imgixImage?.gatsbyImageData ||
                placeholderImage
              }
              imgAltText={
                hit?.featuredImage?.node?.altText ||
                hit?.featuredImage?.node?.slug
              }
              imgHoverTitle={
                hit?.featuredImage?.node?.title ||
                hit?.featuredImage?.node?.slug
              }
              category={hit?.categories?.[0]?.name}
              categoryLink={pathJoinSafeSlashes(hit?.categories?.[0]?.slug)}
              title={hit?.title}
              dateGmt={hit?.dateGmt}
              readTime={hit?.readTime}
            />
          ))}
          {!searching && hits?.length === 0 && (
            <div className={style.noResults}>
              <p>{t('search.noSearchResults')}</p>
              <p>{t('search.trySomethingElse')}</p>
            </div>
          )}
        </div>
      );
    }
  );

  return (
    <div className={style.searchMenu} ref={searchMenuRef}>
      <div className={style.header}>
        <BurgerCloseIcon
          onClick={() => {
            setShouldSearchMenuOpen(false);
          }}
          className={style.burgerCloseIcon}
          width={20}
          height={20}
        />
      </div>
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.GATSBY_ALGOLIA_POSTS_INDEX_NAME}
      >
        <Configure filters={`languageLocale:${locale}`} />
        <div className={style.centralContainerWrapper}>
          <RidestoreLogoLink className={style.ridestoreLogoLink} />
          <div className={style.centralContainer}>
            <ConnectedSearchBox inputRef={searchInputRef} t={t} />
            <div className={style.postsScroller}>
              <ConnectedSearchResults
                placeholderImage={data?.ph10?.childImageSharp?.gatsbyImageData}
                t={t}
              />
            </div>
          </div>
          <BurgerCloseIcon
            onClick={() => {
              setShouldSearchMenuOpen(false);
            }}
            className={style.burgerCloseIconDesktop}
            width={20}
            height={20}
          />
        </div>
      </InstantSearch>
    </div>
  );
};

SearchMenu.propTypes = {};

export default SearchMenu;
