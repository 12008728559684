import { LS_HAS_ACCEPTED_COOKIES } from './localStorageKeys';

/**
 * @param hasAcceptedCookies {boolean}
 */
export const setHasAcceptedCookiesLS = (hasAcceptedCookies) => {
  localStorage.setItem(
    LS_HAS_ACCEPTED_COOKIES,
    JSON.stringify(hasAcceptedCookies)
  );
};

/**
 * @returns {null|boolean}
 */
export const getHasAcceptedCookiesLS = () => {
  if (typeof window === 'undefined') return null;
  return JSON.parse(localStorage.getItem(LS_HAS_ACCEPTED_COOKIES) || 'null');
};
