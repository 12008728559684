import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import '../../i18n';
import { useTranslation } from 'react-i18next';
import { Media } from 'gatsby-plugin-fresnel';
import { useResizeDetector } from 'react-resize-detector';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import HeaderDesktop from '../HeaderDesktop';
import NavMenuMobile from '../NavMenuMobile';
import * as style from './MainLayout.module.scss';
import getIconComponentFromIconName from '../../utilities/iconGetter';
import SearchMenu from '../SearchMenu';
import { MainLayoutNavContext } from '../../contexts/MainLayoutNavContext';
import { MainLayoutLocaleContext } from '../../contexts/MainLayoutLocaleContext';
import LanguageMenu from '../LanguageMenu';
import { allowHtmlScrolling, blockHtmlScrolling } from '../../utilities';
import { getLanguageFromField } from '../../utilities/sharedUtils';
import NavMenuTablet from '../NavMenuTablet';
import OverpassRegular from '../../assets/fonts/Overpass-Regular.woff2';
import OverpassExtraBold from '../../assets/fonts/Overpass-ExtraBold.woff2';
import CookieBanner from '../CookieBanner';
import {
  getHasAcceptedCookiesLS,
  setHasAcceptedCookiesLS,
} from '../../utilities/localStorage/localStorageFunctions';
import { pathJoinSafeSlashes } from '../../utilities/pathJoinSafe';

export const topMenuFields = graphql`
  fragment topMenuFields on WpMenu {
    id
    menuItems {
      nodes {
        parentId
        id
        label
        url
        MenuItemImages {
          menuItemImages {
            id
            imgixImage {
              gatsbyImageData: gatsbyImageData(
                placeholder: BLURRED
                sizes: "390px"
                width: 390
              )
              gatsbyImageData_sm: gatsbyImageData(
                placeholder: BLURRED
                sizes: "36px"
                width: 36
                srcSetMaxWidth: 192
              )
            }
          }
        }
        connectedNode {
          node {
            ... on WpPost {
              id
              slug
              language {
                locale
              }
            }
            ... on WpCategory {
              id
              language {
                locale
              }
              slug
            }
          }
        }
      }
    }
  }
`;

export const otherMenuFields = graphql`
  fragment otherMenuFields on WpMenu {
    id
    menuItems {
      nodes {
        parentId
        id
        label
        url
      }
    }
  }
`;

const currentYear = new Date().getFullYear();

const MainLayout = (props) => {
  const {
    hasNoMarginBelow,
    setNewActiveLanguage,
    children,
    topMenu,
    bottomMenu,
    footer,
    socialMenu,
  } = props;

  const { t, i18n } = useTranslation();
  const { locale } = getLanguageFromField('i18nLocale', i18n.language);

  // const [topMenuHierarchy, setTopMenuHierarchy] = useState([]);
  // const [footerHierarchy, setFooterHierarchy] = useState([]);

  /**
   * Generate hierarchy object for the Top Menu
   * Can be beautifully replaced with a recursive function, but that takes time.
   */
  const getTopMenuHierarchy = () => {
    const nodes = topMenu?.menuItems?.nodes;

    const menuHierarchyTemp = nodes.filter((menuItem) => !menuItem?.parentId);

    for (let i = 0; i < menuHierarchyTemp.length; i += 1) {
      menuHierarchyTemp[i].childMenuItems = nodes.filter(
        (childMenuItem) => childMenuItem?.parentId === menuHierarchyTemp[i].id
      );
    }

    for (let i = 0; i < menuHierarchyTemp.length; i += 1) {
      for (let j = 0; j < menuHierarchyTemp[i].childMenuItems.length; j += 1) {
        menuHierarchyTemp[i].childMenuItems[j].childMenuItems = nodes.filter(
          (childMenuItem) =>
            childMenuItem?.parentId ===
            menuHierarchyTemp[i].childMenuItems[j].id
        );
      }
    }
    return menuHierarchyTemp;
  };
  const topMenuHierarchy = useMemo(() => getTopMenuHierarchy(), []);

  /**
   * Generate hierarchy object for the Footer
   */
  const getFooterHierarchy = () => {
    const footerNodes = footer?.menuItems?.nodes;
    const footerHierarchyTemp =
      footerNodes?.filter((menuItem) => !menuItem?.parentId) ?? [];
    for (let i = 0; i < footerHierarchyTemp.length; i += 1) {
      footerHierarchyTemp[i].childMenuItems = footerNodes.filter(
        (childMenuItem) => childMenuItem?.parentId === footerHierarchyTemp[i].id
      );
    }

    return footerHierarchyTemp;
  };

  const footerHierarchy = useMemo(() => getFooterHierarchy(), []);

  /**
   * Refs
   */

  const headerDesktopRef = useRef();

  /**
   * States
   */

  const [navMenuAnimating, setNavMenuAnimating] = useState(false);
  const [isSearchMenuAnimating, setIsSearchMenuAnimating] = useState(false);
  const [isLanguageMenuAnimating, setIsLanguageMenuAnimating] = useState(false);
  const [isDesktopLanguageMenuAnimating, setIsDesktopLanguageMenuAnimating] =
    useState(false);
  const [isNavMenuDesktopAnimating, setIsNavMenuDesktopAnimating] =
    useState(false);

  const [shouldNavMenuMobileOpen, setShouldNavMenuMobileOpen] = useState(false);
  const [shouldNavMenuTabletOpen, setShouldNavMenuTabletOpen] = useState(false);
  const [shouldSearchMenuOpen, setShouldSearchMenuOpen] = useState(false);
  const [shouldLanguageMenuOpen, setShouldLanguageMenuOpen] = useState(false);

  const [hasSearchMenuOpened, setHasSearchMenuOpened] = useState(false);
  const [hasLanguageMenuOpened, setHasLanguageMenuOpened] = useState(false);
  const [hasNavMenuOpened, setHasNavMenuOpened] = useState(false);

  const [primaryMenuToExpand, setPrimaryMenuToExpand] = useState({});
  const [secondaryMenuToExpand, setSecondaryMenuToExpand] = useState({});

  const [primaryMenuExpanded, setPrimaryMenuExpanded] = useState({});
  const [secondaryMenuExpanded, setSecondaryMenuExpanded] = useState({});

  const isAnythingAnimating =
    navMenuAnimating ||
    isSearchMenuAnimating ||
    isLanguageMenuAnimating ||
    isDesktopLanguageMenuAnimating ||
    isNavMenuDesktopAnimating;

  const toggleNavMenuMobile = () => {
    if (isAnythingAnimating) return;
    if (!hasNavMenuOpened) setHasNavMenuOpened(true);

    setShouldNavMenuMobileOpen((prevState) => !prevState);
    setShouldLanguageMenuOpen(false);
    setNavMenuAnimating(true);
  };

  const toggleNavMenuTablet = () => {
    if (isAnythingAnimating) return;
    if (!hasNavMenuOpened) setHasNavMenuOpened(true);

    setShouldNavMenuTabletOpen((prevState) => !prevState);
    setShouldLanguageMenuOpen(false);
    setNavMenuAnimating(true);
  };

  const openSearchMenu = () => {
    if (isAnythingAnimating) return;
    if (!hasSearchMenuOpened) setHasSearchMenuOpened(true);
    setShouldSearchMenuOpen(true);
  };

  const toggleLanguageMenu = () => {
    if (isAnythingAnimating) return;
    // TODO: fix this
    // if (shouldLanguageMenuOpen) {
    //   headerDesktopRef?.current?.animateCloseLanguageIcon();
    // } else {
    //   headerDesktopRef?.current?.animateOpenLanguageIcon();
    // }
    if (!hasLanguageMenuOpened) setHasLanguageMenuOpened(true);
    setShouldLanguageMenuOpen((prevState) => !prevState);
    setIsLanguageMenuAnimating(true);
  };

  useLayoutEffect(() => {
    if (
      shouldNavMenuMobileOpen ||
      shouldNavMenuTabletOpen ||
      shouldSearchMenuOpen
    ) {
      blockHtmlScrolling();
    } else {
      allowHtmlScrolling();
    }
  }, [shouldNavMenuMobileOpen, shouldNavMenuTabletOpen, shouldSearchMenuOpen]);

  const targetRef = useRef();

  // useLayoutEffect(() => {
  //   const body = document.getElementsByTagName('body');
  //   targetRef.current = body;
  // }, []);

  const onResize = useCallback(
    (width) => {
      if (width > 420 && shouldNavMenuMobileOpen) {
        setShouldNavMenuMobileOpen(false);
        setShouldLanguageMenuOpen(false);
      }
      if (width <= 420 && shouldNavMenuTabletOpen) {
        setShouldNavMenuTabletOpen(false);
        setShouldLanguageMenuOpen(false);
      }

      if (width === 0) {
        setShouldNavMenuTabletOpen(false);
        setShouldNavMenuMobileOpen(false);
        setShouldLanguageMenuOpen(false);
      }
    },
    [shouldNavMenuMobileOpen, shouldNavMenuTabletOpen]
  );

  useResizeDetector({ onResize, targetRef });

  /**
   * Cookie banner
   */
  const [isCookieBannerVisible, setIsCookieBannerVisible] = useState(false);

  useEffect(() => {
    setIsCookieBannerVisible(() => !getHasAcceptedCookiesLS());
  }, []);

  const handleCloseCookieBanner = () => {
    setHasAcceptedCookiesLS(true);
    setIsCookieBannerVisible(false);
  };

  const isMagazine = process.env.GATSBY_RIDESTORE_ENVIRONMENT === 'magazine';
  const isDope = process.env.GATSBY_RIDESTORE_ENVIRONMENT === 'dope';
  const isMontec = process.env.GATSBY_RIDESTORE_ENVIRONMENT === 'montec';

  return (
    <MainLayoutLocaleContext.Provider value={{ locale }}>
      <MainLayoutNavContext.Provider
        value={{
          primaryMenuToExpand,
          primaryMenuExpanded,
          secondaryMenuToExpand,
          secondaryMenuExpanded,
          navMenuAnimating,
          setPrimaryMenuToExpand,
          setPrimaryMenuExpanded,
          setSecondaryMenuToExpand,
          setSecondaryMenuExpanded,
          setNavMenuAnimating,
          shouldLanguageMenuOpen,
          toggleLanguageMenu,
        }}
      >
        <Helmet>
          <link
            rel="preload"
            as="font"
            crossOrigin
            href={OverpassRegular}
            type="font/woff2"
          />
          <link
            rel="preload"
            as="font"
            crossOrigin
            href={OverpassExtraBold}
            type="font/woff2"
          />
        </Helmet>
        {isCookieBannerVisible && (
          <CookieBanner onClose={handleCloseCookieBanner} />
        )}
        <Media lessThan="desktop">
          <Header
            shouldNavMenuMobileOpen={shouldNavMenuMobileOpen}
            shouldNavMenuTabletOpen={shouldNavMenuTabletOpen}
            toggleNavMenuMobile={toggleNavMenuMobile}
            toggleNavMenuTablet={toggleNavMenuTablet}
            openSearchMenu={openSearchMenu}
            headerRef={targetRef}
          />
        </Media>
        <Media greaterThanOrEqual="desktop">
          <HeaderDesktop
            topMenuHierarchy={topMenuHierarchy}
            openSearchMenu={openSearchMenu}
            ref={headerDesktopRef}
            setIsDesktopLanguageMenuAnimating={
              setIsDesktopLanguageMenuAnimating
            }
            isAnythingAnimating={isAnythingAnimating}
            setIsNavMenuDesktopAnimating={setIsNavMenuDesktopAnimating}
            setNewActiveLanguage={setNewActiveLanguage}
          />
        </Media>
        {hasNavMenuOpened && (
          <>
            <NavMenuMobile
              shouldNavOpen={shouldNavMenuMobileOpen}
              topMenuHierarchy={topMenuHierarchy}
              bottomMenu={bottomMenu}
              socialMenu={socialMenu}
            />
            <NavMenuTablet
              shouldNavMenuTabletOpen={shouldNavMenuTabletOpen}
              topMenuHierarchy={topMenuHierarchy}
              bottomMenu={bottomMenu}
              socialMenu={socialMenu}
              toggleNavMenuTablet={toggleNavMenuTablet}
            />
          </>
        )}
        {hasSearchMenuOpened && (
          <SearchMenu
            shouldSearchMenuOpen={shouldSearchMenuOpen}
            setShouldSearchMenuOpen={setShouldSearchMenuOpen}
            setIsSearchMenuAnimating={setIsSearchMenuAnimating}
          />
        )}
        {hasLanguageMenuOpened && (
          <LanguageMenu
            setIsLanguageMenuAnimating={setIsLanguageMenuAnimating}
            shouldNavMenuOpen={shouldNavMenuMobileOpen}
            setNewActiveLanguage={setNewActiveLanguage}
          />
        )}
      </MainLayoutNavContext.Provider>
      <div className={style.topMargin} />
      <div className={style.pageContent}>{children}</div>
      {/* Bottom Nav */}
      <div
        className={`${style.bottomNav} ${
          hasNoMarginBelow ? null : style.bottomMarginInner
        }`}
      >
        <div className={style.bottomNavItemsContainer}>
          {topMenuHierarchy?.map((menuItem) => (
            <Link
              key={menuItem.id}
              className={`clearLinkStyle ${style.navItem}`}
              to={pathJoinSafeSlashes(menuItem?.connectedNode?.node?.slug)}
            >
              {menuItem?.label}
            </Link>
          ))}
        </div>
      </div>
      <div className={style.bottomLinksSection}>
        <div className={style.bottomLinksRow}>
          {/* Footer link */}
          {footerHierarchy?.map((footerColumn) => (
            <div className={style.bottomLinksColumn} key={footerColumn.id}>
              <div className={style.bottomLinksTitle}>{footerColumn.label}</div>
              {footerColumn?.childMenuItems?.map((footerLink) => (
                <a
                  key={footerLink.id}
                  className="d-inline-flex clearLinkStyle"
                  href={footerLink.url}
                >
                  {footerLink.label}
                </a>
              ))}
            </div>
          ))}
          {/* Social media Tablet & Desktop */}
          <Media greaterThanOrEqual="tablet">
            {(mediaClassNames, renderChildren) => (
              <div className={`${style.bottomLinksColumn} ${mediaClassNames}`}>
                {renderChildren && (
                  <>
                    <div className={style.bottomLinksTitle}>
                      {t('footer.socialMedia')}
                    </div>
                    <div className={style.iconRow}>
                      {socialMenu?.menuItems?.nodes?.map((socialIcon) => (
                        <a
                          key={socialIcon.id}
                          href={socialIcon?.url}
                          className={`clearLinkStyle d-inline-block ${style.icon}`}
                          title={`Ridestore on ${socialIcon?.label}`}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                        >
                          {getIconComponentFromIconName(
                            `${socialIcon?.label}Black`
                          )}
                        </a>
                      ))}
                    </div>
                  </>
                )}
              </div>
            )}
          </Media>
        </div>
      </div>
      {/* Social media mobile */}
      <Media lessThan="tablet">
        {(mediaClassNames, renderChildren) => (
          <div
            className={`${style.bottomSocialNetworkMenu} ${mediaClassNames}`}
          >
            {renderChildren && (
              <>
                {socialMenu?.menuItems?.nodes?.map((socialIcon) => (
                  <a
                    key={socialIcon.id}
                    href={socialIcon?.url}
                    className={`clearLinkStyle d-inline-block ${style.icon}`}
                    title={`Ridestore on ${socialIcon?.label}`}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    {getIconComponentFromIconName(`${socialIcon?.label}Black`)}
                  </a>
                ))}
              </>
            )}
          </div>
        )}
      </Media>
      <div className={style.footer}>
        {isMagazine && (
          <>&copy; {currentYear} Ridestore AB. All rights reserved</>
        )}
        {isDope && <>&copy; {currentYear} Dope. All rights reserved</>}
        {isMontec && <>&copy; {currentYear} Montec. All rights reserved</>}
      </div>
    </MainLayoutLocaleContext.Provider>
  );
};

MainLayout.defaultProps = {
  hasNoMarginBelow: false,
};

MainLayout.propTypes = {
  hasNoMarginBelow: PropTypes.bool,
  topMenu: PropTypes.object.isRequired,
  bottomMenu: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
  socialMenu: PropTypes.object.isRequired,
};

export default MainLayout;
