import React, { useContext, useEffect, useRef, useState } from 'react';
import anime from 'animejs/lib/anime.es';
import { MainLayoutNavContext } from '../../contexts/MainLayoutNavContext';
import * as style from './NavMenuMobile.module.scss';
import NavMenu from '../NavMenu/NavMenu';

const NavMenuMobile = (props) => {
  const { shouldNavOpen, topMenuHierarchy, bottomMenu, socialMenu } = props;

  const { setNavMenuAnimating } = useContext(MainLayoutNavContext);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const navMobileWrapper = useRef();

  const animate = (isOpenAnimation) => {
    if (typeof window === 'undefined') return;
    if (isOpenAnimation) {
      anime({
        begin() {
          setIsNavOpen(true);
        },
        targets: navMobileWrapper.current,
        opacity: {
          value: 1,
          easing: 'easeOutCubic',
          duration: 300,
        },
        height: {
          value: '100%',
          easing: 'easeOutElastic(1, 0.7)',
          duration: 1000,
        },
        complete() {
          setNavMenuAnimating(false);
        },
      });
    } else {
      anime({
        targets: navMobileWrapper.current,
        height: {
          value: '0%',
          easing: 'easeInCubic',
          duration: 500,
        },
        complete() {
          setIsNavOpen(false);
          setNavMenuAnimating(false);
        },
      });
    }
  };

  useEffect(() => {
    if (isNavOpen !== shouldNavOpen) {
      animate(shouldNavOpen);
    }
  }, [shouldNavOpen]);

  return (
    <div
      ref={navMobileWrapper}
      className={style.navMobileWrapper}
      style={{ display: isNavOpen ? 'flex' : 'none', height: '0%' }}
    >
      <NavMenu
        topMenuHierarchy={topMenuHierarchy}
        bottomMenu={bottomMenu}
        socialMenu={socialMenu}
      />
    </div>
  );
};

export default NavMenuMobile;
